import './App.css';
import logo from './assets/logo.jpg';
import whatsappLogo from './assets/whatsapp-logo.png';
import banner1 from './assets/banners/banner1.jpg';
import banner2 from './assets/banners/banner2.jpg';
import banner3 from './assets/banners/banner3.jpg';
import banner4 from './assets/banners/banner4.jpg';
import banner5 from './assets/banners/banner5.jpg';
import promocao1 from './assets/promocoes/promocao1.jpg';
import promocao2 from './assets/promocoes/promocao2.jpg';
import promocao3 from './assets/promocoes/promocao3.jpg';
import promocao4 from './assets/promocoes/promocao4.jpg';
import promocao5 from './assets/promocoes/promocao5.jpg';
import promocao6 from './assets/promocoes/promocao6.jpg';
import beneficios from './assets/beneficios.jpg';
import Slider from "react-slick";
import React from 'react';

const useScrollToLocation = () => {
  const scrolledRef = React.useRef(false);
  const { hash } = window.location;//useLocation();
  const hashRef = React.useRef(hash);

  React.useEffect(() => {
    if (hash) {
      // We want to reset if the hash has changed
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrolledRef.current = false;
      }

      // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
      if (!scrolledRef.current) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          scrolledRef.current = true;
        }
      }
    }
  });
};

function SampleNextArrow(props: { className: any; style: any; onClick: any; }) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ display: "block", marginRight: '35px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: { className: any; style: any; onClick: any; }) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ display: "block", marginLeft: '35px', zIndex: 1 }}
      onClick={onClick}
    />
  );
}

function App() {
  const settingsBanner = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className={undefined} style={undefined} onClick={undefined} />,
    prevArrow: <SamplePrevArrow className={undefined} style={undefined} onClick={undefined} />,
  };

  const settingsPromocao = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    nextArrow: <SampleNextArrow className={undefined} style={undefined} onClick={undefined} />,
    prevArrow: <SamplePrevArrow className={undefined} style={undefined} onClick={undefined} />,
  };

  const settingsBenefits = {
    infinite: true,
    slidesToShow: 3,
    speed: 500
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <div className="font-roboto text-center select-none">
      <header className='bg-[#F7F7F7] w-full h-[12vh] flex flex-row justify-between sticky top-0 z-10'>
        <div className='flex w-1/2'>
          <img src={logo} alt='logo' className='h-full' />
        </div>
        <div className='flex flex-column justify-around self-center w-1/2 invisible md:visible md:text-sm lg:text-base'>
          <p className='hover:cursor-pointer hover:text-secondary text-primary font-bold' onClick={() => scrollTo('quemsomos')}>QUEM SOMOS</p>
          <p className='hover:cursor-pointer hover:text-secondary text-primary font-bold' onClick={() => scrollTo('beneficios')}>BENEFÍCIOS</p>
          <p className='hover:cursor-pointer hover:text-secondary text-primary font-bold' onClick={() => scrollTo('promocoes')}>PROMOÇÕES</p>
          <p className='hover:cursor-pointer hover:text-secondary text-primary font-bold' onClick={() => scrollTo('faleconosco')}>FALE CONOSCO</p>
        </div>
        <div className='px-4 py-4 hover:cursor-pointer md:invisible self-center'>
          <div className='w-5 h-0.5 rounded-r-xl rounded-l-xl my-0.5 bg-primary'></div>
          <div className='w-5 h-0.5 rounded-r-xl rounded-l-xl my-0.5 bg-primary'></div>
          <div className='w-5 h-0.5 rounded-r-xl rounded-l-xl my-0.5 bg-primary'></div>
        </div>
      </header>

      {/* banner */}
      <section className='w-full'>
        <Slider {...settingsBanner} >
          <img src={banner1} alt='banner 1'/>
          <img src={banner2} alt='banner 2'/>
          <img src={banner3} alt='banner 3'/>
          <img src={banner4} alt='banner 4'/>
          <img src={banner5} alt='banner 5'/>
        </Slider>
      </section>

      {/* quem somos */}
      <section className='w-full py-6 flex flex-col justify-center' id='quemsomos'>
        <h2 className='h-1/3 font-bold text-primary text-3xl'>QUEM SOMOS</h2>
        <p className='px-2 sm:px-0'>A PetroS é uma empresa Brasileira, 100% Capixaba,  criada para oferecer inovação e tecnologia para o monitoramento e rastreamento de veículos e outros.</p>
        <p className='px-2 sm:px-0'>Nossos produtos combinam infraestrutura tecnológica completa, software e TI próprios, segurança no armazenamento de dados e informações em tempo real sobre localização e trajeto do veículo rastreado.</p>
        <p className='px-2 sm:px-0'>Nossos serviços são oferecidos através de datacenters de alta disponibilidade, 100% espelhados, para segurança total dos dados e acessos dos nossos clientes. Acompanhamos veículos e frotas 24 horas por dia, em qualquer lugar do país, por meio de satélites e GPRS 2G e 4G, através da plataforma Street View, do Google Maps, que permite visualização da posição exata de cada veículo rastreado, proporcionando praticidade, tranquilidade, segurança e privacidade.</p>
        <p className='px-2 sm:px-0'>Desde 2020 no mercado, e técnicos com mais de 10 anos de experiência, a nossa empresa se destaca por ser responsável pelo desenvolvimento do projeto completo, desde o desenvolvimento do software de aplicação até a prestação do serviço de monitoramento veicular para o consumidor final com instalação do rastreador.</p>
      </section>

      {/* EMPRESA 100% CAPIXABA */}
      <section className='bg-secondary w-full py-6 flex flex-col justify-center text-white'>
        <h2 className='h-1/6 font-bold text-3xl'>EMPRESA 100% CAPIXABA</h2>
        <p>GRUPO PETROS RASTREADORES TEM O ENCAIXE PERFEITO PARA VOCÊ!</p>
        <p>Temos orgulho de dizer que somos capixabas, empresa essa que foi pensada e criada para atender nossa região e também todo território nacional, temos serviços diferenciados para seu veículo. Temos uma segurança que cabe no seu bolso.</p>
        <p>Sabemos que a nossa cidade não anda nada segura, pensando nisso O Grupo PetroS montou um MIX de produtos e serviços para melhor atende-lô, tudo isso com GARANTIA e QUALIDADE começando pelo atendimento com as nossas instalações ai mesmo no conforto do seu lar.</p>
        <p>ENTÃO TA ESPERANDO O QUE? NÃO PERCA MAIS TEMPO!</p>
        
        <button
          className='bg-[#00B4A9] hover:bg-primary rounded w-[220px] h-[70px] m-4 self-center'
          onClick={() => window.location.href='https://wa.me/5527988137665'}
        >
          <a
            className='text-white font-bold'
            href='https://wa.me/5527988137665'
            target='blank'
          >
            PEÇA JÁ A SUA COTAÇÃO!
          </a>
        </button>
      </section>

      {/* benefícios */}
      <section className='w-full py-6 flex flex-col justify-end' id='beneficios'>
        <h2 className='h-1/6 font-bold text-primary text-3xl pt-4'>BENEFÍCIOS</h2>
        <img src={beneficios} alt='benefícios'/>
      </section>

      {/* promocões */}
      {/* <section className='w-full h-[32vh] sm:h-[45vh] md:h-[60vh] flex flex-col justify-center'> */}
      <section className='w-full py-6 flex flex-col justify-center' id='promocoes'>
        <h2 className='font-bold text-3xl text-primary pt-2'>PROMOÇÕES</h2>
        {/* <Slider {...settingsPromocao} className='w-[40vh] sm:w-[55vh] md:w-[75vh] self-center'> */}
        <Slider {...settingsPromocao} className='w-[45vh] sm:w-[50vh] self-center'>
          <img src={promocao1} alt='promoção 1'/>
          <img src={promocao2} alt='promoção 2'/>
          <img src={promocao3} alt='promoção 3'/>
          <img src={promocao4} alt='promoção 4'/>
          <img src={promocao5} alt='promoção 5'/>
          <img src={promocao6} alt='promoção 6'/>
        </Slider>
      </section>

    {/* tranquilidade */}
    <section className='bg-secondary w-full py-6 flex flex-col justify-center text-white' id='sobrenos'>
        <h2 className='h-1/6 font-bold text-3xl'>TRANQUILIDADE E SEGURANÇA</h2>
        <p className='px-2 sm:px-0'>AQUI NO GRUPO PETROS RASTREADORES VOCÊ É FELIZ!</p>
        <p className='px-2 sm:px-0'>Cliente PetroS sabe a tranquilidade que sente quando anda pelas ruas da cidade e do interior.</p>
        <p className='px-2 sm:px-0'>Sabe aquele momento que você trabalha e faz as suas atividades tranquilo, sem se preocupar se o seu veículo vai estar no mesmo local que você deixou?</p>
        <p className='px-2 sm:px-0'>Aqui na PetroS é assim, cliente tem um aplicativo moderno e prático e acompanha seu veículo onde e quando quiser 24hs por dia.</p>
        <p className='px-2 sm:px-0'>QUER ANDAR SEGURO E PROTEGIDO(A)? O GRUPO PETROS É A SOLUÇÃO!</p>
        <p className='px-2 sm:px-0'>ENTÃO TA ESPERANDO O QUE? NÃO PERCA MAIS TEMPO!</p>

        <button
          className='bg-[#00B4A9] hover:bg-primary rounded w-[220px] h-[70px] m-4 self-center'
          onClick={() => window.location.href='https://wa.me/5527988137665'}
        >
          <a
            className='text-white font-bold'
            href='https://wa.me/5527988137665'
            target='blank'
          >
            PEÇA JÁ A SUA COTAÇÃO!
          </a>
        </button>
      </section>

      {/* fale conosco */}
      <section className='bg-[#E2E2E2] w-full py-6 flex flex-col justify-center' id='faleconosco'>
        <h2 className='font-bold text-3xl text-primary pb-2 md:pb-6'>FALE CONOSCO</h2>
        <p className='px-2 sm:px-0'>Clique no ícone do WhatsApp e entre em contato conosco mais rapidamente.</p>
        <a href='https://wa.me/5527988137665' target='blank' className='self-center pt-6 hover:cursor-pointer'>
          <img src={whatsappLogo} alt='whatsapp logo' className='w-20'/>
        </a>
      </section>

      {/* footer */}
      <section className='bg-secondary w-full h-[10vh] flex flex-col justify-center'>
        <h2 className='font-bold text-white'>Grupo Petros Rastreadores - Todos os direitos reservados &copy; 2024</h2>
      </section>
    </div>
  );
}

export default App;
